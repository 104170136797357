import { makeStyles } from '@material-ui/styles';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  left: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  ctitle: {
    fontWeight: '1000',
    fontSize: '1.65rem',
    letterSpacing: '0px',
  },
  text: {
    marginLeft: '1rem',
    marginRight: '0.5rem'
  }
};

export default makeStyles(styles, { name: 'UTTO-Components-Header' });
