import { makeStyles } from '@material-ui/styles';

const styles = {
  paper: {
    flex: 1,
    maxWidth: '25rem',
    padding: '1rem',
    textAlign: 'center'
  },
  title: {
    marginTop: '1rem',
    marginBottom: '0.5rem'
  },
  forgotPasswordWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonWrapper: {
    position: 'relative',
    marginTop: '0.5rem'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px'
  },
  logo: {
    margin: '1rem 0',
    maxWidth: '100%'
  },
  noCapsButton: {
    textTransform: 'none'
  },
  registerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginTop: '0.5rem'
  },
  description: {
    marginTop: '1rem'
  }
};

export default makeStyles(styles, { name: 'UTTO-Components-DialogStyles' });
