import { validatorsFor } from '../../utils';

const constraints = {
  email: {
    presence: {
      allowEmpty: false
    },
    email: true
  }
};

export default validatorsFor(constraints);
