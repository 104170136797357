import React from 'react';
import validate from 'validate.js';
import { validatorsFor } from '../../utils';

const PassStrengthFailed = () => {
  return (
    <div>
      Password must include at least three of the below groups:<br/><br/>
      Numerals 0-9<br/>
      Lower case letters<br/>
      Upper case letters<br/>
      Special characters<br/>
    </div>
  );
}

validate.validators.custompw = function(v, options, key, attributes) {
  let n = 0;
  if (/[0-9]/.test(v)) n += 1;
  if (/[a-z]/.test(v)) n += 1;
  if (/[A-Z]/.test(v)) n += 1;
  if (/[^a-zA-Z0-9]/.test(v)) n += 1;

  if (n < 3) {
    return <PassStrengthFailed />;
  }
  return null;
};

const baseConstraints = {
  newPassword: {
    presence: {
      allowEmpty: false
    },
    length: {
      minimum: 8
    },
    custompw: {
    }
  },
  confirmPassword: {
    presence: {
      allowEmpty: false
    },
    equality: {
      attribute: 'newPassword',
      message: 'must match the "New password"'
    }
  }
};

const currentPasswordExtra = {
  currentPassword: {
    presence: {
      allowEmpty: false
    }
  }
};

const selectValidators = requireCurrentPassword =>
  requireCurrentPassword
    ? validatorsFor({ ...baseConstraints, ...currentPasswordExtra })
    : validatorsFor(baseConstraints);

export default selectValidators;
