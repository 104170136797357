import { validatorsFor } from '../../utils';

const constraints = {
  email: {
    presence: {
      allowEmpty: false
    },
    email: true
  },
  password: {
    presence: {
      allowEmpty: false
    }
  }
};

export default validatorsFor(constraints);
