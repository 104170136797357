import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useClassOverrides, useFormFields } from '../../hooks';
import validators from './validator';
import useStyles from '../dialogStyles';

const initialFields = {
  email: ''
};

const ForgotPassword = ({
  title,
  error,
  success,
  loading,
  buttonLabel,
  registerLabel,
  onSubmit,
  onSignIn,
  emailProps = {},
  buttonProps = {},
  classes: classOverrides = {}
}) => {
  const internalClasses = useStyles();
  const classes = useClassOverrides(internalClasses, classOverrides);

  const [fields, errors, hasErrors, onChange, onSubmitForm] = useFormFields(
    initialFields,
    validators,
    onSubmit
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" className={classes.title}>
        {title || 'Forgot your password?'}
      </Typography>
      <Typography className={classes.description}>
        Enter the email address you registered your account with and we will
        send you a password reset link
      </Typography>
      <form noValidate className={classes.form} onSubmit={onSubmitForm}>
        <TextField
          autoFocus
          required
          fullWidth
          margin="normal"
          label="Email address"
          name="email"
          autoComplete="email"
          error={Boolean(errors?.email)}
          helperText={errors?.email?.[0]}
          value={fields.email}
          onChange={onChange}
          {...emailProps}
        />
        <Typography color="error">{error}</Typography>
        {success && (
          <Typography color="primary">
            A password reset link has been emailed to you
          </Typography>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || hasErrors}
            className={classes.button}
            {...buttonProps}
          >
            {buttonLabel || 'Request Reset Link'}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.progress} />
          )}
        </div>
        {onSignIn && (
          <div className={classes.registerWrapper}>
            <Typography>Remembered your password?</Typography>
            &nbsp;
            <Button
              size="small"
              color="primary"
              onClick={onSignIn}
              className={classes.noCapsButton}
            >
              {registerLabel || 'Sign In'}
            </Button>
          </div>
        )}
      </form>
    </Paper>
  );
};

ForgotPassword.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  registerLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onSignIn: PropTypes.func,
  emailProps: PropTypes.object,
  buttonProps: PropTypes.object,
  classes: PropTypes.shape({
    paper: PropTypes.string,
    title: PropTypes.string,
    form: PropTypes.string,
    progress: PropTypes.string,
    buttonWrapper: PropTypes.string,
    button: PropTypes.string
  })
};

export default ForgotPassword;
