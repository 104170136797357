import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useClassOverrides, useFormFields } from '../../hooks';
import validators from './validator';
import useStyles from '../dialogStyles';

const initialFields = {
  newPassword: '',
  confirmPassword: ''
};

const ChangePassword = ({
  title,
  error,
  success,
  loading,
  buttonLabel,
  successLabel,
  onSubmit,
  requireCurrentPassword = true,
  passwordProps = {},
  buttonProps = {},
  classes: classOverrides = {}
}) => {
  const internalClasses = useStyles();
  const classes = useClassOverrides(internalClasses, classOverrides);

  const [fields, errors, hasErrors, onChange, onSubmitForm] = useFormFields(
    requireCurrentPassword
      ? { ...initialFields, currentPassword: '' }
      : initialFields,
    validators(requireCurrentPassword),
    onSubmit
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" className={classes.title}>
        {title || 'Change your password'}
      </Typography>
      <form noValidate className={classes.form} onSubmit={onSubmitForm}>
        {requireCurrentPassword && (
          <TextField
            autoFocus
            required
            fullWidth
            margin="normal"
            type="password"
            label="Current password"
            name="currentPassword"
            autoComplete="current-password"
            error={Boolean(errors?.currentPassword)}
            helperText={errors?.currentPassword?.[0]}
            value={fields.currentPassword}
            onChange={onChange}
            {...passwordProps}
          />
        )}
        <TextField
          autoFocus={!requireCurrentPassword}
          required
          fullWidth
          margin="normal"
          type="password"
          label="New password"
          name="newPassword"
          autoComplete="new-password"
          error={Boolean(errors?.newPassword)}
          helperText={errors?.newPassword?.[0]}
          value={fields.newPassword}
          onChange={onChange}
          {...passwordProps}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          type="password"
          label="Confirm new password"
          name="confirmPassword"
          autoComplete="new-password"
          error={Boolean(errors?.confirmPassword)}
          helperText={errors?.confirmPassword?.[0]}
          value={fields.confirmPassword}
          onChange={onChange}
          {...passwordProps}
        />
        <Typography color="error">{error}</Typography>
        {success && (
          <Typography color="primary">
            {successLabel || 'Password successfully changed'}
          </Typography>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || hasErrors}
            className={classes.button}
            {...buttonProps}
          >
            {buttonLabel || 'Change password'}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.progress} />
          )}
        </div>
      </form>
    </Paper>
  );
};

ChangePassword.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  successLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  requireCurrentPassword: PropTypes.bool,
  passwordProps: PropTypes.object,
  buttonProps: PropTypes.object,
  classes: PropTypes.shape({
    paper: PropTypes.string,
    title: PropTypes.string,
    buttonWrapper: PropTypes.string,
    button: PropTypes.string,
    progress: PropTypes.string
  })
};

export default ChangePassword;
