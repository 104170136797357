export default {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  versionLabel: {
    alignSelf: 'flex-end',
    margin: '0.5rem'
  }
};
