import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import styles from './styles.js';

const Sidebar = ({
  isDrawerOpen,
  onToggleDrawer,
  buttons,
  logo,
  classes = {},
  version
}) => {
  const drawer = (
    <div className={classes.container}>
      <div>
        <Toolbar className={classes.toolbar}>
          {typeof logo === 'string' ? (
            <img
              src={logo}
              alt="UTTO Logo"
              height="48px"
              className={classes.logo}
            />
          ) : (
            logo
          )}
        </Toolbar>
        <Divider />
        <List>
          {buttons.map(({ label, icon, active, onClick }) => (
            <ListItem
              button
              key={label}
              selected={active}
              disabled={!onClick}
              onClick={onClick}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </div>
      {version && (
        <Typography variant="caption" className={classes.versionLabel}>
          {version}
        </Typography>
      )}
    </div>
  );

  return (
    <nav className={classes.nav}>
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          open={isDrawerOpen}
          onClose={onToggleDrawer}
          classes={{
            paper: classes.drawer
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          classes={{
            paper: classes.drawer
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

Sidebar.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isDrawerOpen: PropTypes.bool.isRequired,
  onToggleDrawer: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      active: PropTypes.bool,
      onClick: PropTypes.func
    })
  ),
  classes: PropTypes.shape({
    nav: PropTypes.string,
    logo: PropTypes.string,
    toolbar: PropTypes.string,
    drawer: PropTypes.string,
    drawerPaper: PropTypes.string
  })
};

export default withStyles(styles)(Sidebar);
