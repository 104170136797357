import validate from 'validate.js';

export const validatorsFor = constraints => ({
  all: fields => validate(fields, constraints),
  single: (name, value) => {
    const fieldsSingle = { [name]: value };
    const constraintsSingle = { [name]: constraints[name] };

    const result = validate(fieldsSingle, constraintsSingle);
    return result ? result[name] : result;
  }
});
