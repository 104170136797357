import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useClassOverrides, useFormFields } from '../../hooks';
import validators from './validator';
import useStyles from '../dialogStyles';

const initialFields = {
  email: '',
  password: ''
};

const Login = ({
  logo,
  title,
  error,
  loading,
  buttonLabel,
  registerLabel,
  onSubmit,
  onForgotPassword,
  onRegister,
  emailProps = {},
  passwordProps = {},
  buttonProps = {},
  classes: classOverrides = {}
}) => {
  const internalClasses = useStyles();
  const classes = useClassOverrides(internalClasses, classOverrides);

  const [fields, errors, hasErrors, onChange, onSubmitForm] = useFormFields(
    initialFields,
    validators,
    onSubmit
  );

  const [visible, setVisible] = useState(false);
  const pwdStyles = {
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      marginTop: "1.8em",
    },
  };
  
  return (
    <Paper className={classes.paper}>
      {typeof logo === 'string' ? (
        <img
          src={logo}
          alt="UTTO Logo"
          width="320px"
          className={classes.logo}
        />
      ) : (
        logo
      )}
      <Typography variant="h4" className={classes.title}>
        {title || 'Sign in'}
      </Typography>
      <form noValidate className={classes.form} onSubmit={onSubmitForm}>
        <TextField
          autoFocus
          required
          fullWidth
          margin="normal"
          label="Email address"
          name="email"
          autoComplete="email"
          error={Boolean(errors?.email)}
          helperText={errors?.email?.[0]}
          value={fields.email}
          onChange={onChange}
          {...emailProps}
        />
        <div style={pwdStyles.container}>
          <TextField
            required
            fullWidth
            margin="normal"
            label="Password"
            name="password"
            type={visible ? "text" : "password"}
            autoComplete="current-password"
            error={Boolean(errors?.password)}
            helperText={errors?.password?.[0]}
            value={fields.password}
            onChange={onChange}
            {...passwordProps}
          />
          <div style={pwdStyles.icon} onClick={() => setVisible(!visible)}>
            { visible ? (<VisibilityIcon/>) : (<VisibilityOffIcon/>)}
          </div>
        </div>
        {onForgotPassword && (
          <div className={classes.forgotPasswordWrapper}>
            <Button
              size="small"
              color="primary"
              onClick={onForgotPassword}
              className={classes.noCapsButton}
            >
              Forgot Password?
            </Button>
          </div>
        )}
        <Typography color="error">{error}</Typography>
        <div className={classes.buttonWrapper}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || hasErrors}
            className={classes.button}
            {...buttonProps}
          >
            {buttonLabel || 'Sign in'}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.progress} />
          )}
        </div>
        {onRegister && (
          <div className={classes.registerWrapper}>
            <Typography>Don't have an account?</Typography>
            &nbsp;
            <Button
              size="small"
              color="primary"
              onClick={onRegister}
              className={classes.noCapsButton}
            >
              {registerLabel || 'Register'}
            </Button>
          </div>
        )}
      </form>
    </Paper>
  );
};

Login.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  registerLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onForgotPassword: PropTypes.func,
  onRegister: PropTypes.func,
  emailProps: PropTypes.object,
  passwordProps: PropTypes.object,
  buttonProps: PropTypes.object,
  classes: PropTypes.shape({
    paper: PropTypes.string,
    logo: PropTypes.string,
    title: PropTypes.string,
    form: PropTypes.string,
    progress: PropTypes.string,
    buttonWrapper: PropTypes.string,
    button: PropTypes.string
  })
};

export default Login;
