import { useState, useMemo, useCallback } from 'react';

const useFormFields = (initialFields, validators, onSubmit) => {
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState({});

  const hasErrors = useMemo(() => Object.values(errors).some(val => val), [
    errors
  ]);

  const onChange = useCallback(
    event => {
      event.preventDefault();
      const { name, value } = event.target;
      const newFields = { ...fields, [name]: value };

      setFields(newFields);
      // confirm password needs to be validated alongside new password
      if (name === 'confirmPassword') {
        setErrors(validators.all(newFields) || {});
      } else {
        setErrors({ ...errors, [name]: validators.single(name, value) });
      }
    },
    [fields, validators, errors]
  );

  const onSubmitForm = useCallback(
    event => {
      event.preventDefault();

      const newErrors = validators.all(fields);
      setErrors(newErrors || {});
      if (!newErrors) onSubmit(fields);
    },
    [fields, onSubmit, validators]
  );

  return [fields, errors, hasErrors, onChange, onSubmitForm];
};

export default useFormFields;
