import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useClassOverrides } from '../../hooks';
import useStyles from './styles';

const Header = ({
  logo,
  title,
  ctitle,
  name,
  menuItems,
  classes: classOverrides = {}
}) => {
  const internalClasses = useStyles();
  const classes = useClassOverrides(internalClasses, classOverrides);

  const [anchorElement, setAnchorElement] = useState(null);
  const onCloseMenu = useCallback(() => setAnchorElement(null), [
    setAnchorElement
  ]);
  const onOpenMenu = useCallback(
    event => setAnchorElement(event.currentTarget),
    [setAnchorElement]
  );

  return (
    <AppBar color="primary" position="relative" className={classes.bar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.left}>
          {typeof logo === 'string' ? (
            <img
              src={logo}
              alt="UTTO Logo"
              height="36px"
              className={classes.logo}
            />
          ) : (
            logo
          )}
          <Typography
            color="inherit"
            variant="h6"
            className={classnames(classes.text, classes.title)}
          >
            {title}
          </Typography>
        </div>
        <div className={classes.center}>
          <Typography
            color="inherit"
            variant="h6"
            className={classnames(classes.text, classes.ctitle)}
          >
            {ctitle}
          </Typography>
        </div>
        {name && (
          <div className={classes.right}>
            <Typography
              color="inherit"
              variant="h6"
              className={classnames(classes.text, classes.name)}
            >
              {name}
            </Typography>
            {menuItems ? (
              <div>
                <IconButton color="inherit" onClick={onOpenMenu}>
                  <AccountCircle fontSize="large" />
                </IconButton>
                <Menu
                  open={Boolean(anchorElement)}
                  anchorEl={anchorElement}
                  onClose={onCloseMenu}
                  className={classes.menu}
                >
                  {menuItems}
                </Menu>
              </div>
            ) : (
              <AccountCircle fontSize="large" />
            )}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  ctitle: PropTypes.string,
  name: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.instanceOf(MenuItem)),
  classes: PropTypes.shape({
    bar: PropTypes.string,
    toolbar: PropTypes.string,
    logo: PropTypes.string,
    title: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    text: PropTypes.string,
    name: PropTypes.string,
    menu: PropTypes.string
  })
};

export default Header;
