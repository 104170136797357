export default theme => ({
  coloredHead: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main
  },
  coloredHeadTitle: {
    color: 'rgba(255, 255, 255, 0.8)',
    '&:hover, &:focus, &.active': {
      color: theme.palette.primary.contrastText
    },
    // increasing specificity by repeating class
    '& .icon.icon.icon.icon': {
      color: theme.palette.primary.contrastText
    }
  },
  clickableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});
