import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import styles from './styles';

// TODO: put this note in the documentation somewhere when we have some
// NOTE: if a `uniqueKey` prop is provided, it will be used for the rows' keys
// as well as for setting the selected row and sent to the onClickRow callback

const Table = ({
  data,
  uniqueKey,
  columns,
  colorHead,
  dense,
  sortedByKey,
  sortedDirection,
  onClickHeader,
  onClickRow,
  selectedRows = [],
  classes = {}
}) => (
  <MuiTable size={dense ? 'small' : 'medium'}>
    <TableHead>
      <TableRow>
        {columns &&
          columns.map(column => (
            <TableCell
              key={column.key}
              align={column.align}
              sortDirection={
                sortedByKey === column.key ? sortedDirection : false
              }
              classes={{ head: colorHead && classes.coloredHead }}
            >
              {sortedByKey && sortedDirection ? (
                <TableSortLabel
                  hideSortIcon={dense}
                  active={sortedByKey === column.key}
                  direction={sortedDirection}
                  onClick={() => onClickHeader?.(column.key)}
                  classes={{
                    root: colorHead && classes.coloredHeadTitle,
                    icon: 'icon',
                    active: 'active'
                  }}
                >
                  {column.title}
                </TableSortLabel>
              ) : (
                column.title
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {data &&
        data.map((datum, index) => (
          <TableRow
            key={datum[uniqueKey] || index}
            selected={selectedRows.includes(datum[uniqueKey] || index)}
            className={classnames(datum.classes?.row, {
              [classes.clickableRow]: onClickRow && !datum.disableRow
            })}
            hover={onClickRow && !datum.disableRow}
            onClick={() =>
              !datum.disableRow && onClickRow?.(datum[uniqueKey] || index)
            }
          >
            {columns.map(column => (
              <TableCell
                key={column.key}
                align={column.align}
                className={datum.classes?.cells}
              >
                {column.formatter?.(datum[column.key]) || datum[column.key]}
              </TableCell>
            ))}
          </TableRow>
        ))}
    </TableBody>
  </MuiTable>
);

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      disableRow: PropTypes.bool,
      classes: PropTypes.shape({
        row: PropTypes.string,
        cells: PropTypes.string
      })
    })
  ),
  uniqueKey: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.node,
      formatter: PropTypes.func,
      align: PropTypes.oneOf(['center', 'right', 'left', 'justify', 'inherit'])
    })
  ).isRequired,
  colorHead: PropTypes.bool,
  dense: PropTypes.bool,
  sortedByKey: PropTypes.string,
  sortedDirection: PropTypes.oneOf(['asc', 'desc']),
  onClickHeader: PropTypes.func,
  onClickRow: PropTypes.func,
  selectedRows: PropTypes.array,
  classes: PropTypes.object
};

export default withStyles(styles)(Table);
